import { graphql } from "gatsby";
import React from "react";
import BlogCategory from "../templates/BlogCategory";
import { GetAllBlogPostsQuery } from "../generated/graphql";

interface AllBlogPostsProps {
  data: {
    allContentfulPost: {
      edges: GetAllBlogPostsQuery[];
    };
  };
}

const Blog = ({ data }: AllBlogPostsProps) => {
  const { edges: blogPosts } = data?.allContentfulPost;

  if (!blogPosts) {
    return <></>;
  }

  return <BlogCategory blogPosts={blogPosts} />;
};

export default Blog;

export const pageQuery = graphql`
  query GetAllBlogPosts {
    allContentfulPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          author {
            name
          }
          date(formatString: "DD MMMM YYYY")
          excerpt
          slug
          title
          category
          coverImage {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
